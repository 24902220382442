//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapState} from "vuex";

import SignCard from "@/components/documents/table/SignCard";
import TwoUsersIcon from "@/components/icons/TwoUsersIcon";
import ActionsCard from "@/components/documents/table/ActionsCard";
import MinTrud from "@/components/icons/MinTrud";

export default {
  name: "DocumentsTable",
  components: {SignCard, TwoUsersIcon, ActionsCard, MinTrud},
  props: {
    documents: Array,
    getDocuments: Function,
    isDocuments: Boolean,
    isPersonnelDocuments: Boolean,
    total: Number,
    limitChoices: Array,
    changeDocument: Function,
    scroll: Function,
    setScroll: Function,
    signRules: Object,
    ecSignRules: Array,
  },
  data() {
    return {
      showPreviewLoader: false,
      limit: 25,
      pageNumber: 1,
      isDocumentsChecked: false,
    }
  },
  created(){
    this.isDocumentsChecked = this.documents.some((document) => document.checked);
  },
  updated() {
    if(document.documentElement.clientWidth <= 978) {
      this.scroll()
      this.setScroll()
    }
  },
  methods: {
    showDocument(document) {
      this.showPreview([document.id])
      //this.setDocParamsToDownload(document)
    },
    /*async downloadDocument(document) {
      await utils.downloadDocument(document, this.setShowPreviewLoader)
    },*/

    setShowPreviewLoader(isShow) {
      this.previewLoader = isShow
    },

    showScansPreviews(docs) {
      this.$router.push({
        name: 'document-view',
        params: {
          docs,
        }
      })
    },
    countScans(scans) {
      return scans.length
    },
    setPageNumber(number) {
      this.pageNumber = number;
      this.$store.commit('setDocumentPageNumber', number);
    },
    isCurrentPage(number) {
      if (this.pageNumber == number) {
        return true;
      }
      return false;
    },
    couldBeSigned(document) {
      // if (!this.signRules[document.type]) return false;
      if(document.regNum || !document.needRegNum || document.processed) return true;
      return false;
    },
    getDocumentDate(date, factDate) {
      if (factDate == "") {
        return date;
      }
      return factDate;
    },
    handleCheckAllDocuments(){
      this.documents.forEach((document) => {
        if (!(document.needRegNum && !document.regNum))
          document.checked = !this.isDocumentsChecked;
      })
      this.isDocumentsChecked = !this.isDocumentsChecked;
    },
    handleTestAllChecked(doc) {
      doc.checked = !doc.checked;
      if (this.isDocumentsChecked) {
        this.isDocumentsChecked = false;
      } else {
        if (doc.checked) {
          this.isDocumentsChecked = !this.documents.some((document) => {
            if (document.needRegNum && !document.regNum) return false;
            return !document.checked;
          })
        }
      }
    },
    getDocumentNumber(document) {
      return document.altNumber !== null && document.altNumber !== undefined ? document.altNumber : document.docId;
    },
  },
  computed: {
    documentType() {
      return this.isPersonnelDocuments
        ? 'persDoc' : 'concreteDoc'
    },
    pageCount() {
      return Math.ceil(this.total/this.limit);
    },
    ...mapState([
      'showDocumentsLoader',
    ])
  },
  watch: {
    limit: function(_oldValue, newValue) {
      this.isDocumentsChecked = false;
      this.$store.commit('setDocumentPageLimit', newValue);
    }
  }
}
